import { toast } from "react-toastify"
import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import { revuerTaskApproval } from "../../context/actions/task"
import { useEffect, useState } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import RejectionReasonModel from "./RejectionReasonModel";

const RejectModal = (props ) => {
    const [isshow,setShow] = useState(false);
    const [isValue,setValue] = useState('');
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const  onClose =()=>{
        document.getElementById("modalOnReject").classList.toggle("hidden");
    }
    useEffect(()=>{
       
        if(isValue){ 
            setShow(false)
            handleYes(false);
            toast.success("Rejected Successfully");
            return ;
        }
     
       
    },[isValue]);
    const  handleYes =(btnshow)=>{
      if(props.isParent){
            value = props.rejectReason; 
      }else{
        var value = isValue 
        btnshow?setShow(true):""
        btnshow?onClose():""
        if(value == ''){
            return;
        }
       
      }
     
      const pathname = window.location.pathname;
      const newpathname = pathname.split("/");
      const campaign_token = newpathname[3];
      const revuer_token = newpathname[4];
      const revuer_task_token =props.resubmitdata.revuer_task_token
      const approval_type = 6
      const task_button_type = 2
      const remark_message = value

      
      dispatch(revuerTaskApproval({ campaign_token: campaign_token, 
          revuer_token: revuer_token,
          revuer_task_token: revuer_task_token, 
          approval_type: approval_type,
          task_button_type: task_button_type, 
          remark_message: remark_message, 
          message:"Rejected",

          }, navigate))
         // toggleModal13()
         document.getElementById("modalOnReject").classList.toggle("hidden");
          setTimeout(function(){
              window.location.reload()
          },1000)
    }
  

    return (
        <>
        <div className={`fixed z-10 overflow-y-auto top-0 w-full left-0 ${props.isModalOpen}`} id="modalOnReject">
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed top-1/3 sm:inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
            <div
                className="inline-block align-center bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
            >
                <div className="bg-white px-8 pt-4 pb-4 sm:p-6 sm:pb-4">
                    {/* <h5 className="text-2xl text-center mb-4 font-semibold">Reject</h5> */}
                    
                    <p className="mb-4 text-center">
                    This campaign will close here and the RevuER will not be able to move ahead for further amplification
                    </p>
                    <p className="mb-4 text-center">
                    Are you sure you want to Reject this task?

                    </p>
                    
                    
                    {/* Close Button (Positioned in the top-right corner) */}
                    <button
                        onClick={onClose} // Replace with your close function
                        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    >
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
    
                    {/* Additional Buttons */}
                    <div className="flex justify-center space-x-4 mt-4">
                        <button
                            className="px-4 py-2 bg-green-500 text-white rounded-lg"
                            onClick={handleYes} // Replace with your "Yes" function
                        >
                            Yes
                        </button>
                        <button
                            className="px-4 py-2 bg-red-500 text-white rounded-lg"
                            onClick={onClose} // Replace with your "No" function
                        >
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    {isshow && <RejectionReasonModel  setShow={setShow} setValue={setValue} onClick={handleYes} /> }
    </>
  

    );
};

export default RejectModal