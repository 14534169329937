export const BRAND_REGISTER = "BRAND_REGISTER"
export const SET_BRAND_REGISTER = "SET_BRAND_REGISTER"

export const BRAND_CREATE_PASSWORD = "BRAND_CREATE_PASSWORD"
export const SET_BRAND_CREATE_PASSWORD = "SET_BRAND_CREATE_PASSWORD"

export const BRAND_FORGOT_PASSWORD = "BRAND_FORGOT_PASSWORD"
export const SET_BRAND_FORGOT_PASSWORD = "SET_BRAND_FORGOT_PASSWORD"

export const BRAND_RESET_PASSWORD = "BRAND_RESET_PASSWORD"
export const SET_BRAND_RESET_PASSWORD = "SET_BRAND_RESET_PASSWORD"

export const BRAND_LOGIN = "BRAND_LOGIN"
export const SET_BRAND_LOGIN = "SET_BRAND_LOGIN"

export const CATEGORY_LIST = "CATEGORY_LIST"
export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST"

export const BRAND_UPDATE = "BRAND_UPDATE"
export const SET_BRAND_UPDATE = "SET_BRAND_UPDATE"

export const BRAND_DETAILS = "BRAND_DETAILS"
export const SET_BRAND_DETAILS = "SET_BRAND_DETAILS"

export const CAMPAIGN_TYPE = "CAMPAIGN_TYPE"
export const SET_CAMPAIGN_TYPE = "SET_CAMPAIGN_TYPE"

export const REVUER_LIMIT_LIST = "REVUER_LIMIT_LIST"
export const SET_REVUER_LIMIT_LIST = "SET_REVUER_LIMIT_LIST"

export const INTEREST_LIST = "INTEREST_LIST"
export const SET_INTEREST_LIST = "SET_INTEREST_LIST"

export const CAMPAIGN_CREATE = "CAMPAIGN_CREATE"
export const SET_CAMPAIGN_CREATE = "SET_CAMPAIGN_CREATE"

export const CAMPAIGN_LIST = "CAMPAIGN_LIST"
export const SET_CAMPAIGN_LIST = "SET_CAMPAIGN_LIST"

export const ALL_PENDING_CAMPAIGN_LIST = "ALL_PENDING_CAMPAIGN_LIST"
export const SET_ALL_PENDING_CAMPAIGN_LIST = "SET_ALL_PENDING_CAMPAIGN_LIST"

export const ALL_ORDERS_CAMPAIGN_LIST = "ALL_ORDERS_CAMPAIGN_LIST";
export const SET_ALL_ORDERS_CAMPAIGN_LIST = "SET_ALL_ORDERS_CAMPAIGN_LIST";

export const BRAND_CAMPAIGN_CREATE = "BRAND_CAMPAIGN_CREATE"
export const SET_BRAND_CAMPAIGN_CREATE = "SET_BRAND_CAMPAIGN_CREATE"

export const CAMPAIGN_DETAILS = "CAMPAIGN_DETAILS"
export const SET_CAMPAIGN_DETAILS = "SET_CAMPAIGN_DETAILS"

export const BRAND_CAMPAIGN_UPDATE = "BRAND_CAMPAIGN_UPDATE"
export const SET_BRAND_CAMPAIGN_UPDATE = "SET_BRAND_CAMPAIGN_UPDATE"

export const VIDEO_DURATION = "VIDEO_DURATION"
export const SET_VIDEO_DURATION = "SET_VIDEO_DURATION"

export const DISPLAY_CAMPAIGN_DETAILS = "DISPLAY_CAMPAIGN_DETAILS"
export const SET_DISPLAY_CAMPAIGN_DETAILS = "SET_DISPLAY_CAMPAIGN_DETAILS"

export const ALL_REVIEW_CAMPAIGN_LIST = "ALL_REVIEW_CAMPAIGN_LIST"
export const SET_ALL_REVIEW_CAMPAIGN_LIST = "SET_ALL_REVIEW_CAMPAIGN_LIST"

export const ALL_ACTIVE_CAMPAIGN_LIST = "ALL_ACTIVE_CAMPAIGN_LIST"
export const SET_ALL_ACTIVE_CAMPAIGN_LIST = "SET_ALL_ACTIVE_CAMPAIGN_LIST"

export const ALL_COMPLETED_CAMPAIGN_LIST = "ALL_COMPLETED_CAMPAIGN_LIST"
export const SET_ALL_COMPLETED_CAMPAIGN_LIST = "SET_ALL_COMPLETED_CAMPAIGN_LIST"

export const ALL_DECLINED_CAMPAIGN_LIST = "ALL_DECLINED_CAMPAIGN_LIST"
export const SET_ALL_DECLINED_CAMPAIGN_LIST = "SET_ALL_DECLINED_CAMPAIGN_LIST"

export const REVUER_PENDING_APPROVAL = "REVUER_PENDING_APPROVAL"
export const SET_REVUER_PENDING_APPROVAL = "SET_REVUER_PENDING_APPROVAL"

export const REVUER_ACCEPTED_LIST = "REVUER_ACCEPTED_LIST"
export const SET_REVUER_ACCEPTED_LIST = "SET_REVUER_ACCEPTED_LIST"

export const REVUER_ALL_TASK_LIST = "REVUER_ALL_TASK_LIST"
export const SET_REVUER_ALL_TASK_LIST = "SET_REVUER_ALL_TASK_LIST"

export const REVUER_COMPLETED_TASK_LIST = "REVUER_COMPLETED_TASK_LIST"
export const SET_REVUER_COMPLETED_TASK_LIST = "SET_REVUER_COMPLETED_TASK_LIST"

export const BRAND_RESEND_EMAIL = "BRAND_RESEND_EMAIL"
export const SET_BRAND_RESEND_EMAIL = "SET_BRAND_RESEND_EMAIL"

export const REVUER_PARTICIPATION_APPROVAL = "REVUER_PARTICIPATION_APPROVAL"
export const SET_REVUER_PARTICIPATION_APPROVAL = "SET_REVUER_PARTICIPATION_APPROVAL"

export const REVUER_PENDING_TASK_LIST = "REVUER_PENDING_TASK_LIST"
export const SET_REVUER_PENDING_TASK_LIST = "SET_REVUER_PENDING_TASK_LIST"

export const INFLUENCER_TASK_DETAILS = "INFLUENCER_TASK_DETAILS"
export const SET_INFLUENCER_TASK_DETAILS = "SET_INFLUENCER_TASK_DETAILS"

export const TESTIMONIAL_TASK_DETAILS = "TESTIMONIAL_TASK_DETAILS"
export const SET_TESTIMONIAL_TASK_DETAILS = "SET_TESTIMONIAL_TASK_DETAILS"

export const VISUAL_TASK_DETAILS = "VISUAL_TASK_DETAILS"
export const SET_VISUAL_TASK_DETAILS = "SET_VISUAL_TASK_DETAILS"

export const REVUER_TASK_APPROVAL = "REVUER_TASK_APPROVAL"
export const SET_REVUER_TASK_APPROVAL = "SET_REVUER_TASK_APPROVAL"

export const FEEDBACK_MESSAGE_SUBMIT = "FEEDBACK_MESSAGE_SUBMIT"
export const SET_FEEDBACK_MESSAGE_SUBMIT = "SET_FEEDBACK_MESSAGE_SUBMIT"

export const CAMPAIGN_DELETE = "CAMPAIGN_DELETE"
export const SET_CAMPAIGN_DELETE = "SET_CAMPAIGN_DELETE"

export const GET_NOTIFICATION = "GET_NOTIFICATION"
export const SET_NOTIFICATION = "SET_NOTIFICATION"

export const GET_TOTAL_ACTION_COUNT = "GET_TOTAL_ACTION_COUNT"
export const SET_GET_TOTAL_ACTION_COUNT = "SET_GET_TOTAL_ACTION_COUNT"

export const CAMPAIGN_STATUS_CHANGE = "CAMPAIGN_STATUS_CHANGE"
export const SET_CAMPAIGN_STATUS_CHANGE = "SET_CAMPAIGN_STATUS_CHANGE"

export const BRAND_PROFILE_UPDATE = "BRAND_PROFILE_UPDATE"
export const SET_BRAND_PROFILE_UPDATE = "SET_BRAND_PROFILE_UPDATE"

export const BRAND_EMAIL_OTP_SEND = "BRAND_EMAIL_OTP_SEND"
export const SET_BRAND_EMAIL_OTP_SEND = "SET_BRAND_EMAIL_OTP_SEND"

export const BRAND_ANALYSIS_REPORT = "BRAND_ANALYSIS_REPORT"
export const SET_BRAND_ANALYSIS_REPORT = "SET_BRAND_ANALYSIS_REPORT"

export const REVUER_PARTICIPANTS_REPORT = "REVUER_PARTICIPANTS_REPORT"
export const SET_REVUER_PARTICIPANTS_REPORT = "SET_REVUER_PARTICIPANTS_REPORT"

export const GET_CAMPAIGN_NAMES = "GET_CAMPAIGN_NAMES"
export const SET_CAMPAIGN_NAMES = "SET_CAMPAIGN_NAMES"

export const GET_ONGOING_CAMPAIGN_NAMES = "GET_ONGOING_CAMPAIGN_NAMES"
export const SET_ONGOING_CAMPAIGN_NAMES = "SET_ONGOING_CAMPAIGN_NAMES"

export const GET_BRAND_CAMPIGN_REPORT = "GET_BRAND_CAMPIGN_REPORT"
export const SET_BRAND_CAMPIGN_REPORT = "SET_BRAND_CAMPIGN_REPORT"

export const REVUER_ONGOING_REPORT = "REVUER_ONGOING_REPORT"
export const SET_REVUER_ONGOING_REPORT = "SET_REVUER_ONGOING_REPORT"

export const BRAND_SOCIAL_DATA_UPDATE = "BRAND_SOCIAL_DATA_UPDATE"
export const SET_BRAND_SOCIAL_DATA_UPDATE = "SET_BRAND_SOCIAL_DATA_UPDATE"

export const GET_ONGOING_CAMPAIGN_NAMES_IO = "GET_ONGOING_CAMPAIGN_NAMES_IO"
export const SET_ONGOING_CAMPAIGN_NAMES_IO = "SET_ONGOING_CAMPAIGN_NAMES_IO"

