import siranimg from "../../assets/images/siran.svg";
import celendericon from "../../assets/images/calendar-black.svg";
import uploadicon from "../../assets/images/upload.svg";
import { Header, Sidebar } from "../../layouts";
import { NavLink as Link } from "react-router-dom";
import selecticon from "../../assets/images/down-select.svg";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { customSelect } from "../../utils/custom-select";
import { useNavigate } from "react-router-dom";
import { lightpicker } from "../../services/lightpicker";
//import { campaignCreate } from "../../context/actions/auth"
function Campaignselepage() {

  const { register, handleSubmit, reset } = useForm()
  const dispatch = useDispatch();


  let navigate = useNavigate();
  const submitButton = useRef()
  const toastId = useRef(null);

  const [nameValidateError, setNameValidateError] = useState('')
  const [campObjectiveValidateError, setCampObjectiveValidateError] = useState('')
  const [campTypeValidateError, setCampTypeValidateError] = useState('')
  const [dateRangeValidateError, setDateRangeValidateError] = useState('')
  const [doValidateError, setDoValidateError] = useState('')
  const [dontValidateError, setDontBrandValidateError] = useState('')
  const [additionalsValidateError, setAdditionalsValidateError] = useState('')
  const [budgetValidateError, setBudgetValidateError] = useState('')
  const [revuerLimitValidateError, setrevuerLimitValidateError] = useState('')
  const [socialValidateError, setSocialValidateError] = useState('')

  const onSubmit = data => {
    var name = document.getElementById("name");
    var campObjective = document.getElementById("campObjective");
    var campType = document.getElementById("campType");
    var date = document.getElementById("date");
    var budget = document.getElementById("budget");
    var revuerLimit = document.getElementById("revuerLimit");

    if (data.name.trim() == "") {
      setNameValidateError("Please enter campaign name");
      name.classList.add("error");
    } else {
      setNameValidateError("");
      name.classList.remove("error");
    }

    if (data.campObjective.trim() == "") {
      setCampObjectiveValidateError("Please enter campaign objective");
      campObjective.classList.add("error");
    } else {
      setCampObjectiveValidateError("");
      campObjective.classList.remove("error");
    }

    if (data.campType.trim() == "") {
      setCampTypeValidateError("Please enter campaign type");
      campType.classList.add("error");
    } else {
      setCampTypeValidateError("");
      campType.classList.remove("error");
    }

    if (data.budget.trim() == "") {
      setBudgetValidateError("Please enter budget");
      budget.classList.add("error");
    } else {
      setBudgetValidateError("");
      budget.classList.remove("error");
    }

    if (data.revuerLimit.trim() == "") {
      setrevuerLimitValidateError("Please select revuer limit");
      revuerLimit.classList.add("error");
    } else {
      setrevuerLimitValidateError("");
      revuerLimit.classList.remove("error");
    }

    if ((data.name.trim() != "") && (data.campObjective.trim() != "") && (data.campType.trim() != "") && (data.budget.trim() != "") && (data.revuerLimit.trim() != "")) {
      data.brandlogin_unique_token = brandlogin_unique_token;
    //  dispatch(campaignCreate(data, navigate, submitButton, toastId))
    }
  }

  useEffect(() => {
    document.getElementsByClassName("select-selected").length == 0 &&
      customSelect();
    lightpicker()
  }, []);
  return (
    <>
      <section className="pl-0 md:pl-60 lg:pl-[15rem] lg:pt-0 md:pt-0 pt-14">
        <Header
          welcome="Campaign "
          extra="Create New Campaign "
          showCampaign={false}
        />

        <form onSubmit={handleSubmit(onSubmit)} className="theme-form ng-untouched ng-pristine ng-valid" method="post">
          <div className="px-5 sm:px-8">
            <div className="mb-10 mt-6 sm:mt-0">
              <h5 className="text-xl font-semibold tracking-wide lable-color mb-2">
                Campaign Details <span className="text-[#E74C3C]">*</span>
              </h5>
              <div className="md:block lg:flex block box-shadow-1 rounded-2xl pt-8">
                <div className="flex-initial w-full md:full lg:w-7/12 bac-1 py-8 px-8 pr-4 md:py-5 lg:py-0 mr-2 mb-0 md:mb-2 lg:mb-5">
                  <label className="mt-6 lable-color text-sm tracking-wide font-semibold">
                    Campaign Name{" "}
                  </label>
                  <div className="block mb-4 relative">
                    <input
                      type="text"
                      id="email"
                      placeholder="Campaign Name"
                      required=""
                      className="mb-1 h-12 px-2 rounded-lg border border-[#95A5A6] bg-white w-full mt-2 text-sm focus:outline-none"
                    />
                    <div className="absolute top-5 right-4">
                      <img src={siranimg} alt="siranimg" className="w-7" />
                    </div>
                  </div>

                  <label className="mt-6 lable-color text-sm tracking-wide font-semibold">
                    Campaign Objective
                  </label>
                  <div className="block">
                    <textarea
                      className="resize-none mb-5 h-20 py-3 px-2 rounded-lg border border-[#95A5A6] bg-white w-full mt-2 text-sm focus:outline-none"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Write From here"
                    ></textarea>
                  </div>

                  <div className="sm:flex block w-full social-break float-right mb-2">
                    <div className="flex-initial w-full sub-width-2 sm:w-6/12 mr-6">
                      <label className="lable-color text-sm tracking-wide font-semibold">
                        Campaign Type
                      </label>
                      <div className="block relative">
                        <div className="custom-select mt-2">
                          <select className="focus:outline-none">
                            <option className="select-options color-3" value="0">Select campaign type</option>
                            <option value="1">None</option>
                            <option value="3">Product Review</option>
                            <option value="4">Visual/Video Review</option>
                            <option value="3">Testimonials</option>
                            <option value="4">Sampling</option>
                            <option value="4">Surveys</option>
                            <option value="3">Market Research</option>
                            <option value="4">Influencer Outreach</option>
                          </select>
                        </div>
                        <img
                          src={selecticon}
                          alt="selecticon"
                          className="w-3 h-2.5 absolute top-[20px] right-2.5  block sm:mr-2 sm:m-none mx-auto"
                        />
                      </div>
                    </div>

                    <div className="flex-initial sub-width-2 w-full sm:w-6/12">
                      <label className="lable-color text-sm tracking-wide font-semibold">
                        Campaign Date Range
                      </label>
                      <div className="block relative">
                        <input
                          type="text"
                          id="calender_select"
                          placeholder="dd/mm/yyyy  to  dd/mm/yyyy "
                          required=""
                          className="box-shadow-3 h-12 px-2 rounded-lg border border-[#95A5A6] bg-white w-full mt-2 mb-1 color-3 text-sm pr-12 focus:outline-none"
                        />
                        <div className="absolute top-[1.4rem] right-4">
                          <img
                            src={celendericon}
                            alt="celendericon"
                            className="w-5"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-initial w-full md:11/12 lg:w-6/12 bac-1 py-5 px-8 sm:px-2 md:py-5 lg:py-0 mr-5 mb-0 md:mb-2 sm:mb-0">
                  <h4 className="text-sm tracking-wide font-semibold mb-3 sm:mb-3">
                    Cover Image
                  </h4>

                  <div className="flex w-full justify-center bg-grey-lighter">
                    <label className="w-full flex flex-col items-center px-4 py-[6.7rem] border-gray-500 text-blue rounded-lg border-2 border-dashed tracking-wide   border-blue cursor-pointer">
                      <img src={uploadicon} alt="uploadicon" />
                      <span className="mt-4 text-base leading-normal lable-color">
                        Drag & Drop or browse to choose a file
                      </span>
                      <input type="file" className="hidden" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <h5 className="text-xl font-semibold lable-color mb-2">Other</h5>
            <div className="mb-10 box-shadow-1 rounded-2xl pt-9 px-8 pb-8">
              <div className="md:block lg:flex block">
                <div className="flex-initial w-full md:11/12 lg:w-7/12 bac-1 py-2 lg:pr-4 md:py-5 lg:py-0 mr-2 mb-0 md:mb-2 lg:mb-2">
                  <label className="mt-6 lable-color text-base font-semibold">Do’s</label>
                  <div className="block">
                    <textarea className="resize-none box-shadow-3 h-20 py-3 px-2 rounded-lg border border-[#95A5A6] bg-white w-full mt-2 mb-1 text-sm focus:outline-none" id="exampleFormControlTextarea1" rows="3" placeholder="Write From here"></textarea>
                  </div>
                </div>

                <div className="flex-initial w-full md:11/12 lg:w-6/12 bac-1 py-2 md:px-0 lg:px-0 md:py-5 lg:py-0  mb-0 md:mb-2 sm:mb-0">
                  <label className="mt-6 lable-color text-base font-semibold">Dont’s</label>
                  <div className="block">
                    <textarea className="resize-none box-shadow-3 h-20 py-3 px-2 rounded-lg border border-[#95A5A6] bg-white w-full mt-2 mb-1 text-sm focus:outline-none" id="exampleFormControlTextarea1" rows="3" placeholder="Write From here"></textarea>
                  </div>
                </div>
              </div>

              <label className="mt-2 lable-color text-base font-semibold">Any Additional Details</label>
              <div className="block">
                <textarea className="resize-none mb-6 h-20 py-3 px-2 box-shadow-3 rounded-lg border border-[#95A5A6] bg-white w-full mt-2  text-sm focus:outline-none" id="exampleFormControlTextarea1" rows="3" placeholder="Write From here"></textarea>
              </div>

              <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-11 grid-set gap-1 mt-1">
                <div>
                  <div class="ram">
                    <div>
                      <input id="toggle0" type="checkbox" />
                      <label for="toggle0">Reading</label>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="ram">
                    <div>
                      <input id="toggle9" type="checkbox" />
                      <label for="toggle9">Programming</label>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="ram">
                    <div>
                      <input id="toggle8" type="checkbox" />
                      <label for="toggle8">Leadership</label>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="ram">
                    <div>
                      <input id="toggle7" type="checkbox" />
                      <label for="toggle7">Writing</label>
                    </div>
                  </div>
                </div>

                <div className=" w-36 sm:w-32 ">
                  <div class="ram">
                    <div>
                      <input id="toggle1" type="checkbox" />
                      <label for="toggle1">Traning or Teaching</label>
                    </div>
                  </div>
                </div>

                <div className="ml-0 sm:ml-5 mx-0">
                  <div class="ram">
                    <div>
                      <input id="toggle2" type="checkbox" />
                      <label for="toggle2">Knowledge</label>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="ram">
                    <div>
                      <input id="toggle3" type="checkbox" />
                      <label for="toggle3">Fashion</label>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="ram">
                    <div>
                      <input id="toggle4" type="checkbox" />
                      <label for="toggle4">Travel</label>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="ram">
                    <div>
                      <input id="toggle5" type="checkbox" />
                      <label for="toggle5">Designing</label>
                    </div>
                  </div>

                </div>
                <div>
                  <div class="ram">
                    <div>
                      <input id="toggle6" type="checkbox" />
                      <label for="toggle6">  Meditation</label>
                    </div>
                  </div>

                </div>

                <div className="flex items-center ml-4">
                  <div class="ram">
                    <div>
                      <a href="#" class="color-2 font-semibold underline text-xs">View More</a>
                    </div>
                  </div>

                </div>



              </div>
            </div>

            <div className="md:block lg:flex block social-break mb-5">
              <div className="flex-initial w-full md:11/12 sub-width-2 lg:w-7/12 bac-1 py-2 sm:py-8  pr-4 md:py-5 lg:py-0 mr-2 mb-0 md:mb-2 lg:mb-5">
                <h5 className="text-lg tracking-wide font-semibold lable-color mb-2">
                  Campaign Budget & Revuer Limit
                  <span className="text-[#E74C3C]">*</span>
                </h5>

                <div className="box-shadow-1 rounded-2xl py-6 px-6 sm:px-8">
                  <div className="md:block lg:flex block mb-3">
                    <div className="flex-initial w-full md:11/12 lg:w-7/12 bac-1 mr-6 mb-0">
                      <label className="mt-6 lable-color text-sm tracking-wide font-semibold">
                        Campaign Budget
                      </label>
                      <div className="block relative">
                        <input
                          type="text"
                          id="date"
                          placeholder="Enter Budget"
                          required=""
                          className="box-shadow-3 h-12 px-2 rounded-lg border border-[#95A5A6] bg-white w-full mt-2 mb-2 placeholder:text-[#95a5a6] text-sm pl-20"
                        />
                        <div className="absolute top-2 left-0 py-3 px-6 border-r border-[#95A5A6]">
                          ₹
                        </div>
                      </div>
                    </div>

                    <div className="flex-initial w-full md:11/12 lg:w-7/12 bac-1">
                      <label className="mt-6 lable-color text-sm tracking-wide font-semibold">
                        Revuer Limit<span className="text-[#E74C3C]">*</span>
                      </label>
                      <div className="block relative">
                        <div className="custom-select mt-2">
                          <select>
                            <option className="select-options color-3" value="0">select revuer limit</option>
                            <option value="1">None</option>
                            <option value="3">20 Revuers</option>
                            <option value="4">30 Revuers</option>
                            <option value="3">40 Revuers</option>
                            <option value="4">60 Revuers</option>
                          </select>
                        </div>
                        <img
                          src={selecticon}
                          alt="selecticon"
                          className="w-3 h-2.5 absolute top-[20px] right-2.5  block sm:mr-2 sm:m-none mx-auto"
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div className="md:block lg:flex block mb-5">
              <div className="flex-initial w-full md:11/12 lg:w-6/12 bac-1 py-2 lg:py-2 px-2 pr-4 md:py-5 mr-2 mb-0 md:mb-2 lg:mb-5">
                <h5 className="text-xl tracking-wide font-semibold lable-color mb-2">
                  Select Social Platform<span className="text-[#E74C3C]">*</span>
                </h5>
                <div className="box-shadow-1 rounded-xl py-8 py-s px3 px-5">
                  <div className="block lg:flex social-break">
                    <div className="block sm:flex socila-mb ">
                      <div className="form-check form-check-inline flex items-center mr-4 mb-5 lg:mb-0">
                        <input
                          className="form-check-input appearance-none h-4 w-4 border border-black rounded bg-white checked:bg-[#FCB43C] checked:border-none focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          id="inlineCheckbox2"
                          value="option1"
                        />
                        <label
                          className="form-check-label inline-block text-black text-xs"
                          htmlFor="inlineCheckbox4"
                        >
                          Facebook
                        </label>
                      </div>

                      <div className="form-check form-check-inline flex items-center mr-4 mb-5 lg:mb-0">
                        <input
                          className="form-check-input appearance-none h-4 w-4 border border-black rounded bg-white checked:bg-[#FCB43C] checked:border-none focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          id="inlineCheckbox6"
                          value="option1"
                        />
                        <label
                          className="form-check-label inline-block text-black text-xs"
                          htmlFor="inlineCheckbox1"
                        >
                          Instagram
                        </label>
                      </div>
                      <div className="form-check form-check-inline flex items-center mr-4 mb-5 lg:mb-0">
                        <input
                          className="form-check-input appearance-none h-4 w-4 border border-black rounded bg-white checked:bg-[#FCB43C] checked:border-none focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          id="inlineCheckbox7"
                          value="option1"
                        />
                        <label
                          className="form-check-label inline-block text-black text-xs"
                          htmlFor="inlineCheckbox1"
                        >
                          Twitter
                        </label>
                      </div>
                    </div>

                    <div className="block sm:flex ">
                      <div className="form-check form-check-inline flex items-center mr-4 mb-5 lg:mb-0">
                        <input
                          className="form-check-input appearance-none h-4 w-4 border border-black rounded bg-white checked:bg-[#FCB43C] checked:border-none focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          id="inlineCheckbox1"
                          value="option1"
                        />
                        <label
                          className="form-check-label inline-block text-black text-xs"
                          htmlFor="inlineCheckbox1"
                        >
                          Youtube
                        </label>
                      </div>

                      <div className="form-check form-check-inline flex items-center mr-4 mb-5 lg:mb-0">
                        <input
                          className="form-check-input appearance-none h-4 w-4 border border-black rounded bg-white checked:bg-[#FCB43C] checked:border-none focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          id="inlineCheckbox8"
                          value="option1"
                        />
                        <label
                          className="form-check-label inline-block text-black text-xs"
                          htmlFor="inlineCheckbox1"
                        >
                          Pintrest
                        </label>
                      </div>

                      <div className="form-check form-check-inline flex items-center mr-4 mb-5 lg:mb-0">
                        <input
                          className="form-check-input appearance-none h-4 w-4 border border-black rounded bg-white checked:bg-[#FCB43C] checked:border-none focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          id="inlineCheckbox9"
                          value="option1"
                        />
                        <label
                          className="form-check-label inline-block text-black text-xs"
                          htmlFor="inlineCheckbox1"
                        >
                          LinkedIn
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-initial w-full md:11/12 lg:w-6/12 bac-1  md:py-5 lg:py-0  mb-0 md:mb-2 lg:mb-5">
                <div className="box-shadow-1 rounded-xl py-5 sm:py-7 py-0152 mt-9 px-6">
                  <div className="block social-break sm:flex justify-between">
                    <h5 className="py152 text-lg tracking-wide font-semibold font-sm lable-color mb-6 sm:mb-0">
                      Campaign Post Status
                    </h5>

                    <div className="flex items-center">
                      <label
                        htmlFor="toggle"
                        className="text-base font-medium text-black mr-5"
                      >
                        Active
                      </label>
                      <div className="relative inline-block w-[3.2rem] mr-2 align-middle select-none transition duration-200 ease-in">
                        <input
                          type="checkbox"
                          name="toggle"
                          id="toggle"
                          className="toggle-checkbox absolute ml-1 block w-5 h-5 mt-[3px] rounded-full bg-white appearance-none cursor-pointer"
                        />
                        <label
                          htmlFor="toggle"
                          className="toggle-label block overflow-hidden h-[26px] rounded-full bac-7 cursor-pointer"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-8">
              <div className="flex justify-center sm:justify-end">
                <Link
                  to={`${process.env.REACT_APP_FRONT_URL}/Campaignsele-page`}
                  className="mr-8 bac-3 w-48 py-2.5 rounded-lg text-white uppercase text-base font-bold flex justify-center items-center"
                >
                  Cancel
                </Link>

                <Link
                  to={`${process.env.REACT_APP_FRONT_URL}/campaign-details-page`}
                  className="bac-6 w-48 py-2.5 flex rounded-lg text-white  text-base font-bold justify-center items-center uppercase"
                >
                  submit
                </Link>
              </div>
            </div>

          </div>
        </form>
      </section>
      <Sidebar title="Campaigns" />
    </>
  );
}
export default Campaignselepage;
