import { SET_ALL_ORDERS_CAMPAIGN_LIST } from "../../data/constants";

export const allOrdersCampaignList = [];

export const allOrdersCampaignListData = (state = allOrdersCampaignList, action) => {
    switch (action.type) {
        case SET_ALL_ORDERS_CAMPAIGN_LIST:
            return action.Maindata.data;
        default:
            return state;
    }
};
