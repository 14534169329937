import { SET_ALL_PENDING_CAMPAIGN_LIST } from "../../data/constants"

export const allPendingCampaignList = [];

export const allPendingCampaignListData = (state = allPendingCampaignList, action) => {
    switch (action.type) {
        case SET_ALL_PENDING_CAMPAIGN_LIST:
            return action.Maindata.data
		default:
            return state
    }
}