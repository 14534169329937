import { toast } from "react-toastify"
import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import { revuerTaskApproval } from "../../context/actions/task"
import { useEffect, useState } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";


const RejectionReasonModel = (props ) => {
    let navigate = useNavigate();
    const [isTextBox,setTexbox] = useState("");
    const dispatch = useDispatch();
    const  onClose =()=>{
        props.setShow(false)
        document.getElementById("modalOnReject1").classList.toggle("hidden");
    }   
    const handleSubmit = (e)=>{
        e.preventDefault();
        props.setValue(isTextBox)
        if(isTextBox ==''){
            toast.error("Please enter your Reason for  Rejection");
            return ;
        }
        props.onClick(true)
        
        onClose();
       
    }
    return (
        <div className={`fixed z-10 overflow-y-auto top-0 w-full left-0 ${props.isModalOpen}`} id="modalOnReject1">
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed top-1/3 sm:inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
            <div
                className="inline-block align-center bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
            >
                <div className="bg-white px-8 pt-4 pb-4 sm:p-6 sm:pb-4">
                    {/* <h5 className="text-2xl text-center mb-4 font-semibold">Reject</h5> */}
                    
                    
                    <p className="mb-4 text-center">
                    Please Enter your reason for rejection ?

                    </p>
                    
                    
                    {/* Close Button (Positioned in the top-right corner) */}
                    <button
                        onClick={onClose} // Replace with your close function
                        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    >
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
    
                    {/* Additional Buttons */}
                    <div className="flex justify-center space-x-4 mt-4">
                        
                    <textarea
                      className="flex-grow py-2.5 pl-10 px-4 w-full mr-1 rounded-lg border border-gray-300 resize-none focus:outline-none"
                      rows="1"
                      placeholder="Start typing..."
                      id="text_feedback_reason"
                      name="text_feedback_reason"
                      
                      onChange={(e)=>setTexbox(e.target.value)}
                      style={{ outline: "none" }}
                    ></textarea>
                        <button
                            className="px-4 py-2 bg-red-500 text-white rounded-lg"
                            onClick={handleSubmit}
                            // Replace with your "No" function
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
        
    );
};

export default RejectionReasonModel