import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Header, Sidebar } from "../../layouts";

const ViewOrder = () => {
    const { campaign_token } = useParams();
    const [details, setDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.post(`${process.env.BRAND_API_URL}/brand/campaign/get-all-campaign-order-list`, {
                    campaign_token: campaign_token, 
                    page: currentPage,
                    limit: pageSize
                });
                console.log("API RESPONSE TOKEN", result.data.data[0]);

                if (result.data.data && result.data.data.length > 0) {
                    const fetchedDetails = result.data.data[0].details;

                    if (fetchedDetails.length > 0) {
                        setDetails(fetchedDetails);
                        setTotalPages(result.data.data.totalPages);
                        setError(null); 
                    } else {
                        setError("No orders found for this campaign.");
                        setDetails([]);
                        setTotalPages(1); 
                    }
                } else {
                    setError("No Order Details Found");
                    setDetails([]);
                    setTotalPages(1); 
                }
            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Error fetching data");
                setDetails([]);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [campaign_token, currentPage, pageSize]);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleExportClick = async () => {
        setLoading(true); 
    
        try {
            const response = await axios.post(`${process.env.BRAND_API_URL}/brand/campaign/get-all-data-in-google-sheet`, {
                campaign_token: campaign_token,
            });
    
            console.log("API KA RESSS", response.data.sheet.url);
    
            if (response.status === 200) {
                const url = response.data.sheet.url;
                window.open(url, "_blank");
                setLoading(false); 
            } else {
                console.error('Failed to fetch data:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error exporting data:', error);
        } 
    };

    // Format the date as "day month year"
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }).format(date);
    };

    return (
        <>
            <section className="pl-0 md:pl-60 lg:pl-[15rem] pt-20 md:pt-0 lg:pt-0">
                <Header welcome="Order Details" />
                <div className="px-5">
                    <div className='flex justify-end'>
                        <button
                            disabled={details.length === 0}
                            onClick={handleExportClick}
                            className="w232 lg:block text-sm font-semibold bac-6 text-white uppercase px-6 py-2 rounded-lg mr-10"
                        >
                            Export CSV
                        </button>
                    </div>
                    
                    {loading ? (
                        <div className='flex justify-center items-center'>Loading...</div>
                    ) : error ? (
                        <>
                            <div id="tab-contents">
                                <div id="first" className="pt-6 text-xs sm:text-sm text-justify">
                                    <div className="overflow-x-auto mb-5">
                                        <table className="table-auto w-full whitespace-nowrap">
                                            <thead>
                                                <tr className="bg-[#EBF0F2] h-14">
                                                    <th className="label-color font-medium lg:font-semibold md:font-medium text-xs sm:text-sm px-5 py-2 rounded-l-lg">
                                                        S. No.
                                                    </th>
                                                    <th className="label-color font-medium lg:font-semibold md:font-medium text-xs sm:text-sm px-5 py-2">
                                                    RevuER Name
                                                    </th>
                                                    <th className="label-color font-medium lg:font-semibold md:font-medium text-xs sm:text-sm px-5 py-2">
                                                        Order ID
                                                    </th>
                                                    <th className="label-color font-medium lg:font-semibold md:font-medium text-xs sm:text-sm px-5 py-2">
                                                        Amount
                                                    </th>
                                                    <th className="label-color font-medium lg:font-semibold md:font-medium text-xs sm:text-sm px-5 py-2 rounded-r-lg">
                                                        Order Date
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center">{error}</div>
                        </>
                    ) : (
                        <div id="tab-contents">
                            <div id="first" className="pt-6 text-xs sm:text-sm text-justify">
                                <div className="overflow-x-auto mb-5">
                                    <table className="table-auto w-full whitespace-nowrap">
                                        <thead>
                                            <tr className="bg-[#EBF0F2] h-14">
                                                <th className="label-color font-medium lg:font-semibold md:font-medium text-xs sm:text-sm px-5 py-2 rounded-l-lg">
                                                    S. No.
                                                </th>
                                                <th className="label-color font-medium lg:font-semibold md:font-medium text-xs sm:text-sm px-5 py-2">
                                                    RevuER Name
                                                </th>
                                                <th className="label-color font-medium lg:font-semibold md:font-medium text-xs sm:text-sm px-5 py-2 rounded-r-lg">
                                                    Order Date
                                                </th>
                                                <th className="label-color font-medium lg:font-semibold md:font-medium text-xs sm:text-sm px-5 py-2">
                                                    Order ID
                                                </th>
                                                <th className="label-color font-medium lg:font-semibold md:font-medium text-xs sm:text-sm px-5 py-2">
                                                    Amount
                                                </th>
                                                <th className="label-color font-medium lg:font-semibold md:font-medium text-xs sm:text-sm px-5 py-2">
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {details.map((order, index) => (
                                                <tr key={order._id} className="border-b border-gray-200">
                                                    <td className="text-center px-5 py-3">{(currentPage - 1) * pageSize + index + 1}</td>
                                                    <td className="px-5 py-3">{`${order.revuerDetails.first_name} ${order.revuerDetails.last_name}`}</td>
                                                    <td className="px-5 py-3">{formatDate(order.order_date)}</td>
                                                    <td className="px-5 py-3">{order.order_id}</td>
                                                    <td className="px-5 py-3">{order.payout_amount}</td>
                                                    <td className="px-5 py-3">
                                                        {order.dropout === 1 ? (
                                                          <span style={{ color: 'red' }}>Rejected</span>
                                                       ) : (
                                                            '' // Leave empty if not rejected
                                                         )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {totalPages > 1 && (
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={totalPages}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            shape="rounded"
                                        />
                                    </Stack>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <Sidebar title="Campaigns" />
        </>
    );
};

export default ViewOrder;
